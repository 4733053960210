import React from "react";
import { RichText } from "./RichText";
import { Section } from "./Section";

export const SectionText = ({
  text,
  backgroundColor,
  spaceBottom,
  spaceTop,
  width = 12,
}) => (
  <Section
    width={11}
    className={`pb-${spaceBottom || 5}rem pt-${
      spaceTop || 5
    }rem ${backgroundColor}`}
  >
    <div className={`col-${width}`} data-aos="fade-up" data-aos-delay="100">
      <RichText
        text={text.raw}
        formatOptions={{
          bold: true,
          aCenter: true,
        }}
      />
    </div>
  </Section>
);
