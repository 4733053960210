import React, { useEffect, useRef, useState } from "react";
import { Section } from "./Section";
import { RichText } from "./RichText";
import text, { format } from "../utils/text";
import triangle from "../images/element/shape-triangle.png";
import circle from "../images/element/shape-circle.png";
import { useWindowSize } from "../hooks/useWindowSize";
import { v4 as uuidv4 } from "uuid";

export const Feedbacks = ({ items, title, spaceTop = 5, spaceBottom = 5 }) => {
  const windowSize = useWindowSize();
  const itemEls = useRef({});
  const [feedHeight, setFeedHeight] = useState(0);

  useEffect(() => {
    const feeds = Object.values(itemEls.current);
    const heights = [];

    feeds.map((feed) => {
      const refFeedRect = feed.getBoundingClientRect();

      heights.push(refFeedRect.height);
    });

    setFeedHeight(Math.max(...heights) + 66);
  }, [Object.keys(itemEls.current), windowSize.width]);

  return (
    <Section className={`py-3rem pb-lg-${spaceBottom}rem pt-lg-${spaceTop}rem`}>
      <div className={`col-12`}>
        {title && (
          <h2 className="fs-1_75 extra-bold c-black text-center">{title}</h2>
        )}
        <div className={`position-relative pt-4rem pb-4rem`}>
          <div
            className="position-absolute border-top bw-2 bc-blue"
            style={{ width: "154px", left: "calc(50% - 77px)" }}
          />
        </div>
      </div>
      {items.map((props, index) => (
        <div
          className="col-12 col-md-6 pb-2rem position-relative"
          key={uuidv4()}
        >
          {index % 4 === 0 && (
            <img
              src={triangle}
              alt="instapay triangle shape"
              className="position-absolute etn-1 eln-1 d-none d-sm-inline"
              style={{ zIndex: -1 }}
              data-aos="fade-up"
              data-aos-delay="50"
            />
          )}
          <Feedback
            id={props.id}
            content={props.content}
            person={props.person}
            description={props.description}
            image={props.image}
            feedHeight={feedHeight}
            itemEls={itemEls}
          />
          {items.length === 2 ||
            (index % 4 === 3 && (
              <img
                src={circle}
                alt="instapay circle shape"
                className="position-absolute eb-0_5 ern-1 d-none d-sm-inline"
                style={{ zIndex: -1 }}
                data-aos="fade-up"
                data-aos-delay="50"
              />
            ))}
        </div>
      ))}
      <div className={`col-12`}>
        <div className={`position-relative pt-4rem pb-1rem`}>
          <div
            className="position-absolute border-top bw-2 bc-blue"
            style={{ width: "154px", left: "calc(50% - 77px)" }}
          />
        </div>
      </div>
    </Section>
  );
};

const Feedback = ({
  id,
  content,
  person,
  description,
  image,
  feedHeight,
  itemEls,
}) => (
  <div
    className={`p-2rem border round-1rem shadow bgc-white`}
    data-aos="fade-up"
    data-aos-delay="50"
    style={{ minHeight: `${feedHeight}px` }}
  >
    <div ref={(el) => (itemEls.current[id] = el)}>
      {content && (
        <div className="pb-1rem">
          <RichText text={content.raw} className="font-italic fs-0_875" />
        </div>
      )}
      <div className="d-flex align-items-center">
        {image && (
          <div
            className="bgImage rounded-circle h-5rem w-5rem min-w-5rem"
            style={{ backgroundImage: `url(${image.fluid.src})` }}
          />
        )}
        <div className="pl-1rem">
          <div className="fs-1 py-0_25rem">{person}</div>
          <div className="fs-0_875">{text.format(description)}</div>
        </div>
      </div>
    </div>
  </div>
);
