import React, { useEffect, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";

export const WaveBackground = ({ getOffsetTop, image }) => {
  const windowSize = useWindowSize();
  const [topPos, setTopPos] = useState(0);
  const [imageRef, setImageRef] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    if (!imageRef || !isImageLoaded) {
      return;
    }

    setTimeout(() => {
      let top = imageRef.height - getOffsetTop() - 450;
      if (top < 0) {
        top = 0;
      }

      setTopPos(-top);
    }, 50);
  }, [imageRef, isImageLoaded, windowSize.width]);

  return windowSize.width > 1024 ? (
    <img
      className="position-absolute w-100"
      src={image}
      alt=""
      style={{ top: `${topPos}px`, zIndex: -1 }}
      ref={(el) => setImageRef(el)}
      onLoad={() => setIsImageLoaded(true)}
    />
  ) : (
    <></>
  );
};
