import React from "react";
import { Section } from "./Section";
import loadable from "@loadable/component";
import { TextColumn, Buttons } from "./Common";
const VisaCardAnimation = loadable(() => import("./lottie/VisaCardAnimation"));

export const SectionTextAnimation = ({
  animation,
  animationPosition,
  animationAlign,
  title,
  description,
  backgroundColor,
  buttons,
  separator,
  separatorSize,
  smallText,
  spaceBottom,
  spaceTop,
}) => {
  const animationColumn = animation && (
    <AnimationColumn
      {...{
        animation,
        animationPosition,
        animationAlign,
      }}
    />
  );

  return (
    <Section
      width={11}
      className={`py-3rem pb-lg-${spaceBottom || 5}rem pt-lg-${
        spaceTop || 5
      }rem ${backgroundColor}`}
    >
      {animationPosition === "Left" && animationColumn}
      <TextColumn
        title={title}
        description={description}
        buttons={buttons}
        smallText={smallText}
        separator={separator}
        separatorSize={separatorSize}
        position={animationPosition === "Left" ? "right" : "left"}
      />
      {(!animationPosition || animationPosition === "Right") && animationColumn}
      {buttons && (
        <div className="col-12 d-block d-lg-none pt-4rem">
          <Buttons buttons={buttons} />
        </div>
      )}
    </Section>
  );
};

const AnimationColumn = ({ animation, animationPosition, animationAlign }) => {
  let animationClass = "pr-2rem";

  if (animationPosition === "Right") {
    animationClass = "pl-2rem";
  }

  const getAnimation = (animation) => {
    switch (animation) {
      case "visa-card":
        return <VisaCardAnimation />;
      default:
        return "";
    }
  };

  return (
    <div
      className={`col-12 order-11 col-lg-6 d-none d-lg-block text-${
        animationAlign || "center"
      }`}
    >
      <div className={animationClass}>{getAnimation(animation)}</div>
    </div>
  );
};
