import React, { useState } from "react";
import { Section } from "./Section";
import { RichText } from "./RichText";
import pictogramLeft from "../images/pictograms/pictogram-left.png";
import pictogramMiddle from "../images/pictograms/pictogram-middle.png";
import pictogramRight from "../images/pictograms/pictogram-right.png";
import PrimaryLinkButton from "./PrimaryLinkButton";
import { Button } from "./Button";
import { PopupEbook } from "./popup/PopupEbook";

export const Pictograms = ({
  titleAndTextColumns,
  button,
  backgroundColor,
  pictogramTitleUppercase,
  pictogramsAlign = "center",
  spaceTop = 5,
  spaceBottom = 5,
  variant,
}) => {
  const colNumber = titleAndTextColumns.length;
  const colClass = `col-md-${12 / colNumber}`;
  const [showPopup, setShowPopup] = useState(false);

  const isEbook = variant === "Ebook - form";

  return (
    <>
      <Section
        className={`py-3rem pb-lg-${spaceBottom}rem pt-lg-${spaceTop}rem ${backgroundColor}`}
      >
        {titleAndTextColumns.map(
          (
            { title, titleAlign, text, textAlign, textFontSize, image },
            index
          ) => (
            <div
              className={`col-12 ${colClass} text-center position-relative pb-2rem pb-md-0`}
              key={title}
            >
              {!isEbook && (
                <div
                  className={`position-absolute d-none d-md-block bgc-blue et-4_5 ${getLineClass(
                    index,
                    colNumber,
                    pictogramsAlign
                  )}`}
                  style={{ height: "5px" }}
                />
              )}
              <div
                className={`${
                  isEbook ? "pb-1rem" : "pb-1rem"
                } position-relative ${isEbook ? "h-md-10rem" : "h-md-15rem"} ${
                  pictogramsAlign === "left" && "eln-4_5"
                }`}
              >
                {image && (
                  <div
                    className={`${
                      !isEbook && "position-absolute"
                    } w-100 d-flex justify-content-center align-items-center`}
                    style={{ height: "146px" }}
                  >
                    <img src={image.fluid.src} alt="icon" />
                  </div>
                )}
                {!isEbook && (
                  <img
                    src={getPicotgramImage(index, colNumber)}
                    alt="icon background"
                    className={getPicotgramClass(index, colNumber)}
                  />
                )}
              </div>
              <div
                className={`d-flex flex-wrap flex-column ${
                  isEbook && "justify-content-center align-items-center"
                }`}
              >
                {title && (
                  <h4
                    className={`fs-1_5 font-weight-bold pb-2rem pb-md-0 min-h-md-4rem ${
                      titleAlign || ""
                    } ${pictogramTitleUppercase && "text-uppercase"}`}
                    data-aos="fade-up"
                    data-aos-delay={`${50 + index * 50}`}
                  >
                    {title}
                  </h4>
                )}
                {text && !isEbook && (
                  <div
                    data-aos="fade-up"
                    data-aos-delay={`${100 + index * 50}`}
                    className={textAlign}
                  >
                    <RichText
                      text={text.raw}
                      className={`fs-${textFontSize || "1"}`}
                    />
                  </div>
                )}
                {text && isEbook && (
                  <RichText text={text.raw} className="w-80 text-left h5" />
                )}
              </div>
            </div>
          )
        )}
        {button && !isEbook && (
          <div
            className="col-12 d-flex justify-content-center pt-4rem"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <PrimaryLinkButton
              button={{
                ...button,
                outline: "btn-outline-light",
              }}
            />
          </div>
        )}
        {button && isEbook && (
          <div className={"col-12 d-flex justify-content-center pt-4rem"}>
            <Button
              onClick={() => setShowPopup(true)}
              children={button.text}
              round={4}
              className={`w-100 w-sm-auto mb-2rem py-2 text-uppercase px-3rem fs-0_875 fs-sm-1 ${button.borderWidth} ${button.textColor} ${button.backgroundColor} ${button.outline}`}
            />
          </div>
        )}
      </Section>
      {showPopup && <PopupEbook setShowPopup={setShowPopup} button={button} />}
    </>
  );
};

const getPicotgramImage = (itemNumber, itemsAmount) => {
  if (itemNumber === 0) {
    return pictogramLeft;
  }

  if (itemNumber === itemsAmount - 1) {
    return pictogramRight;
  }

  return pictogramMiddle;
};

const getPicotgramClass = (itemNumber, itemsAmount) => {
  if (itemNumber === 0) {
    return "pr-4";
  }

  if (itemNumber === itemsAmount - 1) {
    return "pl-4";
  }

  return "";
};

const getLineClass = (itemNumber, itemsAmount, position) => {
  if (position === "left") {
    return getLineClassLeft(itemNumber, itemsAmount);
  }

  return getLineClassCenter(itemNumber, itemsAmount);
};

const getLineClassCenter = (itemNumber, itemsAmount) => {
  if (itemNumber === 0) {
    return "w-50 er-0";
  }

  if (itemNumber === itemsAmount - 1) {
    return "w-50 el-0 pictograms__dot";
  }

  return "w-100 el-0 pictograms__dot";
};

const getLineClassLeft = (itemNumber, itemsAmount) => {
  if (itemNumber === 0) {
    return "w-100 el-4_5";
  }

  if (itemNumber === itemsAmount - 1) {
    return "w-50 eln-4_5 pictograms__dot";
  }

  return "w-100 eln-4_5 pictograms__dot";
};
