import React, { useState } from "react";
import CloseIcon from "../CloseIcon";
import { ContactForm } from "../Form/ContactForm";
import tick from "../../images/icon/tick.png";
import { LinkButton } from "../LinkButton";

export const PopupEbook = ({ setShowPopup, button }) => {
  const [isSending, setIsSending] = useState(false);
  const [isSended, setIsSended] = useState(false);

  const handleSubmit = async (values) => {
    setIsSending(true);
    try {
      let resp = await fetch(
        "https://europe-west1-instapay-prod.cloudfunctions.net/apiv2/popup-ebook",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
          },
          body: JSON.stringify(values),
        }
      );
      await resp.json();
      window.lintrk("track", { conversion_id: 9808690 });

      window.history.replaceState(null, null, "#konsultacja");
      setIsSended(true);
      setIsSending(false);
    } catch (error) {
      console.error(error);
      setIsSending(false);
    }
  };

  const handleClose = () => setShowPopup(false);

  return (
    <>
      <div
        className="position-fixed w-100 mt-10"
        style={{
          zIndex: 101,
          marginTop: "5%",
          top: "0",
          bottom: "0",
          overflowY: "auto",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-md-9 d-flex justify-content-center m-auto">
              <div className="bg-white position-relative shadow-popup rounded">
                {!isSending && (
                  <CloseIcon
                    onClick={handleClose}
                    isOpen={true}
                    colorClass="bgc-black bc-black w-70"
                    iconClass="position-absolute er-1 et-1"
                  />
                )}
                <div className="mx-4rem py-3rem position-relative">
                  {isSended ? (
                    <>
                      <div className="text-center">
                        <img src={tick} alt="instapay tick shape" />
                        <h4 className="my-5 font-weight-bold">
                          Twój e-book już do Ciebie leci!
                        </h4>
                      </div>
                      <div className="text-left mt-5">
                        <p>
                          Gotowe! Na podany adres e-mail wysłaliśmy Twój
                          egzemplarz poradnika.
                        </p>
                        <p>
                          Chcesz otrzymywać płatności nawet 2x szybciej i
                          jeszcze skuteczniej zarządzać powiadomieniami?
                        </p>
                        <p className="font-weight-bold">
                          <u>Umów bezpłatną konsultację ze specjalistą</u> i
                          dowiedz się, jak to osiągnąć!
                        </p>
                      </div>
                      <div className="text-center flex-column d-flex">
                        <LinkButton
                          url="https://calendly.com/instapaypl/demo"
                          round={4}
                          className={`w-100 w-sm-auto mb-1rem py-2 text-uppercase px-3rem fs-0_875 fs-sm-1 mt-5 bgc-black bc-black bw-2 c-white`}
                        >
                          UMÓW KONSULTACJĘ
                        </LinkButton>
                        {!isSending && (
                          <span role="button" onClick={handleClose}>
                            Zamknij
                          </span>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <h4>
                        Uzupełnij poniższe pola i{" "}
                        <span className="font-weight-bold">
                          odbierz swój darmowy poradnik!
                        </span>
                      </h4>
                      <p className="my-4">
                        Zwiększ skuteczność swoich powiadomień.
                      </p>
                      <ContactForm
                        handleSubmit={handleSubmit}
                        isSending={isSending}
                        handleClose={handleClose}
                        fieldWidth={100}
                        button={button}
                        colorActive="c-black"
                        borderColorActive="bc-black"
                        backgroundColorActive="bgc-white"
                        isEbook={true}
                        termsCheckbox="Wyrażam zgodę na przetwarzanie przez Cashless Solutions sp. z o.o. moich danych osobowych w postaci imienia, nazwiska adresu poczty elektronicznej oraz numeru telefonu w celu przesyłania mi informacji marketingowych dotyczących produktów i usług oferowanych przez Cashless Solutions sp. z o.o. za pomocą środków komunikacji elektronicznej oraz za pomocą urządzeń telefonicznych, stosownie do treści przepisu art. 10 ust. 1 i 2 ustawy o świadczeniu usług drogą elektroniczną."
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`popupOverlay popupOverlay--open`} />
    </>
  );
};
