import React, { useEffect, useRef, useState } from "react";
import { Section } from "./Section";
import { RichText } from "./RichText";
import Glide from "@glidejs/glide";
import { useWindowSize } from "../hooks/useWindowSize";

export const Partners = ({
  title,
  description,
  items,
  backgroundColor,
  spaceBottom,
  spaceTop,
  width = 12,
  itemsPerSlide = 4,
  showSlider = false,
}) => {
  width = width ? `col-lg-${width} mx-auto` : "";

  return (
    <Section
      width={11}
      className={`text-center pb-${spaceBottom}rem pt-${spaceTop}rem ${backgroundColor}`}
    >
      <div className={`col-12 ${width}`}>
        {title && (
          <h2
            className="fs-1_75 font-weight-bold col-12 pb-1rem"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            {title}
          </h2>
        )}
        {description && (
          <div
            className="fs-1_5 pb-2rem col-12"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <RichText text={description.raw} />
          </div>
        )}
        {showSlider ? (
          <GlideSlider items={items} itemsPerSlide={itemsPerSlide} />
        ) : (
          <AllPartners items={items} />
        )}
      </div>
    </Section>
  );
};

const AllPartners = ({ items }) => (
  <div className="d-flex flex-wrap justify-content-center py-0 py-lg-3rem">
    {items.map(({ image }, index) => (
      <div className="d-flex px-2rem pb-3rem align-items-center" key={index}>
        <img
          src={image.fluid.src}
          alt="icon"
          key={index}
          className="max-h-5rem"
        />
      </div>
    ))}
  </div>
);

const GlideSlider = ({ items, itemsPerSlide }) => {
  const sliderRef = useRef();
  const windowSize = useWindowSize();
  const [perSlide, setPerSlide] = useState(0);

  useEffect(() => {
    if (!windowSize.width) {
      return;
    }

    if (windowSize.width > 1024) {
      setPerSlide(itemsPerSlide);
    } else if (windowSize.width > 768 && itemsPerSlide > 4) {
      setPerSlide(4);
    } else if (windowSize.width > 440 && itemsPerSlide > 3) {
      setPerSlide(3);
    } else if (itemsPerSlide > 2) {
      setPerSlide(2);
    } else {
      setPerSlide(1);
    }
  }, [windowSize.width]);

  useEffect(() => {
    if (perSlide === 0) {
      return;
    }

    new Glide(sliderRef.current, {
      perView: perSlide,
      type: "carousel",
      autoplay: true,
      animationTimingFunc: "linear",
      animationDuration: 1000 * perSlide,
    }).mount();
  }, [perSlide]);

  return (
    <div className="glide" ref={sliderRef}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {items.map(({ image }, index) => (
            <li
              className="glide__slide d-flex align-items-center justify-content-center h-3rem h-lg-4rem h-xl-5rem"
              key={index}
            >
              <img
                src={image.fluid.src}
                alt="icon"
                key={index}
                className="max-h-3rem max-h-lg-4rem max-h-xl-5rem"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
